export const environment = {
  production: false,
  envName: 'staging',
  apiUrl: 'https://azapp-11804-p-002-azappslot-11804-s-002.azurewebsites.net',
  emailList: [
    {
      email: 'vikram.kute1@external.fcagroup.com',
      name: 'Vikram Kute'
    },
    {
      email: 'Samatha.Madanani@external.fcagroup.com',
      name: 'Samatha Madanani'
    },
    {
      email: 'vikram.kute1@external.stellantis.com',
      name: 'Vikram Kute'
    },
    {
      email: 'Samatha.Madanani@external.stellantis.com',
      name: 'Samatha Madanani'
    }
  ],
  redirectUri: 'https://absenteeplanning.staging.fcagroup.com/',
  instance:'https://fed04.fcagroup.com/',
  clientId :'3724f77a-05f9-4629-b6db-7573d35264e4',
  endpoints: {
    api: 'https://graph.microsoft.com'
  }
};
